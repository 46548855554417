<template>
  <div class="users-view page-layout">
    <PageHeader
      title="Vos informations"
      :has-actions="true"
      :has-back="true"
      @back="goBack"
    >
      <md-button
        class="md-primary md-raised"
        @click="$router.push('/admin/superusers/update/' + superuser.id)"
      >
        Editer
      </md-button>
    </PageHeader>

    <!-- ## Les informations de l'utilisateur ## -->
    <md-card class="meep-form">
      <md-card-content>
        <md-list>
          <!-- Username -->
          <md-subheader>
            Nom d'utilisateur
          </md-subheader>

          <md-list-item>
            <div class="md-list-item-text">
              <span>{{ superuser.username }}</span>
            </div>
          </md-list-item>

          <!-- Adresse e-mail -->
          <md-subheader>
            Adresse e-mail
          </md-subheader>

          <md-list-item>
            <div class="md-list-item-text">
              <span>{{ superuser.email }}</span>
            </div>
          </md-list-item>
        </md-list>

        <div class="md-layout md-alignment-center-right">
          <md-button
            class="md-raised md-primary"
            to="/admin/superusers/change-password"
          >
            Modifier votre mot de passe
            <md-icon>
              vpn_key
            </md-icon>
          </md-button>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
/* Modèles */
import superusersModel from "../../../model/superusers";
import PageHeader from "@/components/PageHeader";

export default {
  components: { PageHeader },
  data() {
    return {
      isDebug: false,
      superuser: {},
    };
  },
  methods: {
    goBack() {
      window.history.back();
    },
  },
  /* Récupérer les données de l'utiliateur */
  beforeRouteEnter(to, from, next) {
    superusersModel
      .get(to.params.id)
      .then((superuser) => {
        next((vm) => {
          vm.superuser = superuser;
        });
      })
      .catch(() => {
        next(from.path);
      });
  },
  watch: {
    $route(to) {
      superusersModel
        .get(to.params.id)
        .then((superuser) => {
          this.superuser = superuser;
        })
        .catch((err) => {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.users-view {
  .debug-log {
    max-height: 420px;
    overflow-y: scroll;
  }

  .md-card {
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: auto;
    margin-right: auto;
  }

  .md-subheader {
    min-height: 24px;
    margin-top: 4px;
  }
}
</style>
